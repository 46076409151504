@use '../../../styles/utils' as util;

.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (max-width: 430px) {
    padding: 0;
    &:first-child {
      margin-top: 8px;
    }
  }
}

.textContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  gap: 12px;
  padding: 0 32px;

  @media (max-width: 599px) {
    padding: 0 16px;
    margin-bottom: 0;
  }
}

.info_icon {
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #141937;
  cursor: pointer;
}

.text {
  font-weight: 700;
  line-height: 40px;
  font-family: var(--font-inter);
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 0.00938em;

  @include util.AdaptiveSize(font-size, 20px, 24px, 377px, 1025px);
  color: #ffffff;
  z-index: 5;
  position: relative;
  align-self: center;
  padding-inline: 12px;

  span {
    color: #e1ff7d;
    font-weight: 300;
  }

  &:before {
    content: '';
    position: absolute;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    z-index: -1;
    top: 10px;
    filter: blur(45px);
    transform: translate3d(0, 0, 0);
  }
}

.grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 16px;
  //TODO подогнать под брейкпоинт
  @media (max-width: 1100px) {
    padding: 0;
  }
}

.tooltip_text {
  font-family: var(--font-inter);
  font-size: 14px;
}

.bannerGive {
  border-radius: 18px;
  background: linear-gradient(180deg, #5e42cc 0%, #181c36 100%);

  max-width: 520px;
  display: flex;
  width: 32%;
  overflow: hidden;

  @media (max-width: 1650px) {
    width: 40%;
  }
  @media (max-width: 1250px) {
    width: 60%;
  }
  @media (max-width: 790px) {
    width: 100%;
    @media (max-width: 500px) {
      flex-direction: column;
      position: relative;
    }
  }
}

.sideGive {
  display: flex;
  padding: 8px;
}

.leftSide,
.rightSide {
  width: 50%;
  @media (max-width: 500px) {
    width: 100%;
  }
}

.rightSide {
  padding: 8px 8px 8px 0;
  display: flex;
  align-items: center;

  div:first-child {
    padding: 10px;

    div:first-child {
      padding: 0;
      white-space: nowrap;
    }
  }

  div {
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }

    &:first-child {
      min-width: auto;
      justify-content: space-between;
      min-height: 100%;
    }
  }
}

.leftSide {
  color: #fff;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('../../../assets/images/bannerMainGiveAways.webp');
  background-repeat: no-repeat;
  padding: 50px 17px 24px 18px;
  background-size: cover;

  .leftSideContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;

    p {
      font-family: var(--font-play);
    }
  }
}

.bannerGiveMobile {
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  min-height: 560px;
  display: flex;
  margin-top: 30px;
  flex-direction: column-reverse;
  background-image: url('../../../assets/images/bannerMainGiveAways.webp'),
  linear-gradient(180deg, #5e42cc 0%, #181c36 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
  @media (max-width: 500px) {
    width: 100%;
  }

  .giveAway {
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 500px) {
      top: 44px;
    }
  }

  .giveCard {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: 350px) {
      top: 44%;
    }

    div {
      @media (max-width: 350px) {
        min-width: auto;
      }

      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
    }
  }

  .footerBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    button {
      width: max-content;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: center;
      font-family: var(--font-play);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      text-transform: uppercase;
    }
  }
}
